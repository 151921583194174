<template>
  <div id="search">
    <div
      v-if="getLoadingStatus"
      class="loading"
    >
      <BaseLoading />
    </div>
    <div class="content-wrapper">
      <div
        class="content-container"
      >
        <div class="header">
          <div
            v-if="getLoadingStatus === false"
            class="feedback"
          >
            <span>
              Showing {{ getResults.length }} results for
              <span class="weighted">
                '{{ $route.query.q }}'
              </span>
            </span>
            <span class="viewing">
              Viewing {{ getPageResults.length }} out of
              <span class="weighted">
                {{ getResults.length }}
                results
              </span>
            </span>
          </div>
        </div>
        <div
          v-if="getPageResults.length > 0 && getLoadingStatus === false"
          class="content"
        >
          <div class="sidebar">
            <div class="el-icon">
              <img
                v-if="!gridIcon"
                src="@/assets/grid-grey.svg"
                class="grid-icon el-icon-menu"
                :class="{'active-icon':gridIcon, 'in-active-icon':listIcon}"
                @click="routeGridList('grid')"
              >
              <img
                v-if="gridIcon"
                src="@/assets/grid-active.svg"
                class="grid-icon el-icon-menu"
                :class="{'active-icon':gridIcon, 'in-active-icon':listIcon}"
                @click="routeGridList('grid')"
              >
              <img
                v-if="!listIcon"
                src="@/assets/list-grey.svg"
                class="list-icon el-icon-s-fold"
                :class="{'active-icon':listIcon, 'in-active-icon':gridIcon}"
                @click="routeGridList('list')"
              >
              <img
                v-if="listIcon"
                src="@/assets/list-active.svg"
                class="list-icon el-icon-s-fold"
                :class="{'active-icon':listIcon, 'in-active-icon':gridIcon}"
                @click="routeGridList('list')"
              >
            </div>
            <div
              class="price-range"
            >
              <p class="filter-label">
                Filter by price
              </p>
              <el-slider
                v-model="priceRange"
                range
                :max="highestPrice"
              />
            </div>
            <div
              class="seller-filter-options"
            >
              <p class="filter-label">
                Filter by seller <span
                  v-if="showClearFilter"
                  class="clear-filter"
                  @click="clearFilter()"
                >Clear</span>
              </p>
              <p
                v-for="(seller, index) in getSellerList"
                :key="'key-'+index"
                class="seller-option"
                :class="{'active-filter':seller.vendorId == selectedFilter}"
                @click="filterByVendorId(seller.vendorId)"
              >
                {{ seller.name }}
              </p>
            </div>
          </div>
          <div class="results">
            <router-link
              v-for="product in getPageResults"
              :key="product.id"
              :to="productRoute(product)"
              class="product-card-wrapper"
            >
              <ProductCard
                :product="product"
                :is-market-place="true"
                :my-stock-room-product="false"
              />
            </router-link>
          </div>
        </div>
        <BaseNoData
          v-if="getPageResults.length === 0 && getLoadingStatus === false"
          class="no-results"
        >
          <span class="sorry">Sorry, we couldn't find any results matching '{{ $route.query.q }}'.</span>
          <span class="suggestion">Try using fewer words, or browsing one of our categories below.</span>
          <div>
            <ul class="suggestion-list">
              <li class="suggestion-item">
                <router-link to="/marketplace/category/pharmacy">
                  Pharmacy
                </router-link>
              </li>
              <li class="suggestion-item">
                <router-link to="/marketplace/category/medical-supplies">
                  Medical Supplies
                </router-link>
              </li>
              <li class="suggestion-item">
                <router-link to="/marketplace/category/pharmaceuticals">
                  Pharmaceuticals
                </router-link>
              </li>
              <li class="suggestion-item">
                <router-link to="/marketplace/category/nutraceuticals">
                  Nutraceuticals
                </router-link>
              </li>
              <li class="suggestion-item">
                <router-link to="/marketplace/category/medical-equipment">
                  Medical Equipment
                </router-link>
              </li>
            </ul>
          </div>
        </BaseNoData>
        <div
          v-if="numberOfPages > 1"
          class="pagination-wrapper"
        >
          <BasePagination
            :pages="numberOfPages"
            :current-page="currentPage"
            @previous="prevPage"
            @next="nextPage"
            @page-clicked="setPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseLoading from '@/components/common/BaseLoading.vue';
import BaseNoData from '@/components/common/BaseNoData.vue';
import BasePagination from '@/components/common/BasePagination.vue';
import ProductCard from '@/components/marketplace/common/ProductCard.vue';

export default {
  name: 'Search',
  components: {
    BaseLoading,
    BaseNoData,
    BasePagination,
    ProductCard,
  },
  data() {
    return {
      gridIcon: true,
      listIcon: false,
      showClearFilter: false,
      selectedFilter: undefined,
    };
  },
  computed: {
    ...mapGetters({
      getResults: 'marketplaceSearch/getResults',
      getSimilarResults: 'marketplaceSearch/getSimilarResults',
      getBestResults: 'marketplaceSearch/getBestResults',
      getPageResults: 'marketplaceSearch/getPageResults',
      getLoadingStatus: 'marketplaceSearch/getLoadingStatus',
      numberOfPages: 'marketplaceSearch/getNumberOfPages',
      currentPage: 'marketplaceSearch/getPage',
      getPriceRange: 'marketplaceSearch/getPriceRange',
      highestPrice: 'marketplaceSearch/getHighestPrice',
      getSellerList: 'marketplaceSearch/getSellerList',
      getSelectedSeller: 'marketplaceSearch/getSelectedSeller',
    }),
    priceRange: {
      get() {
        return this.getPriceRange;
      },
      set(priceRange) {
        this.setRange(priceRange);
      },
    },
    selectedSeller: {
      get() {
        return this.getSelectedSeller;
      },
      set(selectedSeller) {
        this.setSelectedSeller(selectedSeller);
      },
    },
  },
  watch: {
    async $route() {
      await this.search();
    },
    highestPrice() {
      this.setRange([0, this.highestPrice]);
    },
    currentSeller() {
      this.setSelectedSeller(this.currentSeller);
    },
  },
  mounted() {
    this.showClearFilter = false;
  },
  async created() {
    await this.setQuery(this.$route.query.q);
    if (this.$route.query.c !== '0' && this.$route.query.c !== undefined) {
      await this.setCategory(this.$route.query.c);
    }
    await this.search();
  },
  methods: {
    ...mapActions({
      setQuery: 'marketplaceSearch/commitSetQuery',
      setCategory: 'marketplaceSearch/commitSetCategory',
      search: 'marketplaceSearch/fetchProductsByQuery',
      nextPage: 'marketplaceSearch/nextPage',
      prevPage: 'marketplaceSearch/prevPage',
      setPage: 'marketplaceSearch/commitPage',
      setRange: 'marketplaceSearch/commitSetPriceRange',
      setSelectedSeller: 'marketplaceSearch/commitSelectedSellet',
    }),
    productRoute(p) {
      const { vin, slug, categorySlug } = p;
      return {
        name: 'Product',
        params: { vin, slug, categorySlug },
      };
    },
    filterByVendorId(id) {
      this.selectedSeller = id;
      this.showClearFilter = true;
      this.selectedFilter = id;
    },
    clearFilter() {
      this.selectedSeller = undefined;
      this.showClearFilter = false;
      this.selectedFilter = undefined;
    },
    routeGridList(key) {
      if (key === 'grid') {
        this.gridIcon = true;
        this.listIcon = false;
        this.$router.push({ name: 'Search', query: { q: this.$route.query.q, c: this.$route.query.c } });
      }
      if (key === 'list') {
        this.listIcon = true;
        this.gridIcon = false;
        this.$router.push({ name: 'SearchList', query: { q: this.$route.query.q, c: this.$route.query.c } });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
#search {
  position:relative;
  width: 100%;
  background: $light-grey;
}
.content-wrapper {
  padding: 0px 20px 75px 20px;
}
.loading {
  height: 60px;
  width: 100%;
  position: absolute;
  top: 0;
}
.price-range {
  padding: 20px 30px 10px 30px;
  background: $white;
  border-radius: 12px;
}
.filter-label {
  font-size: .8em;
  margin: 0 -10px;
  color: $simpatra-blue;
}
.content-container {
  max-width: 1200px;
  margin: 0 auto;
}
.header {
  height: 80px;
  display: flex;
  align-items: center;
}
.feedback {
  font-size: .9em;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  color: $simpatra-blue;
}
.weighted {
  font-weight: 600;
}
.content {
  display: flex;
}
.sidebar {
  width: 225px;
}
.results {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
}
.no-results {
  text-align: center;
  padding: 50px 20px;
}
.sorry {
  color: $simpatra-blue;
  display: block;
  font-weight: 600;
  font-size: 1.25em;
  margin-bottom: 15px;
}
.suggestion {
  color: $simpatra-blue;
  font-size: 1.25em;
}
.suggestion-list {
  list-style-type: none;
}
.suggestion-item a{
  display: inline-block;
  color: $simpatra-orange;
  font-size: 1.25em;
  margin: 10px 0px;
}
.product-card-wrapper {
  margin: 0px 0px 10px 10px;
  text-decoration: none;
}
.pagination-wrapper {
  margin-top: 25px;
}
.seller-filter-options{
  margin-top: 16px;
  padding: 20px 30px 10px 30px;
  background: $white;
  border-radius: 12px;
}
.seller-filter-options .filter-label {
  margin-bottom: 16px;
}
.seller-option {
  cursor: pointer;
  color: #000;
  display: block;
  text-decoration: underline;
  padding: 4px 0;
  margin-bottom: 6px;
}
.seller-option:hover, .seller-option.active-filter {
  color: #f05a28;
}
.el-icon {
  margin-bottom: 16px;
}
.grid-icon {
  border-right: 1px solid lightgrey;
  cursor: pointer;
}
.list-icon {
  padding-left: 5px;
  cursor: pointer;
}
.clear-filter {
  float: right;
  color: #f05a28;
  cursor: pointer;
  font-weight: 700;
  padding: 0 4px;
  border: 1px solid #fff;
}
.clear-filter:hover {
  border: 1px solid #f05a28;
  border-radius: 4px;
}
@media screen and (max-width: 1020px) {
  .sidebar {
    display: none;
  }
  .results {
    margin-left: 0;
    justify-content: center;
  }
  .viewing {
    font-size: .8em;
  }
}
</style>
