<template>
  <div id="category">
    <div
      v-if="getLoadingStatus"
      class="loading"
    >
      <BaseLoading />
    </div>
    <div class="content-wrapper">
      <div class="content-container">
        <div class="header">
          <div
            v-if="getLoadingStatus === false"
            class="feedback"
          >
            <MarketplaceBreadcrumbs :crumbs="breadcrumbs" />
            <span class="viewing">
              Viewing {{ getPageResults.length }} out of
              <span class="weighted">
                {{ getResults.length }}
                results
              </span>
            </span>
          </div>
        </div>
        <div class="content">
          <div class="sidebar">
            <div class="price-range">
              <p class="filter-label">
                Filter by price
              </p>
              <el-slider
                v-model="priceRange"
                range
                :max="highestPrice"
              />
            </div>
          </div>
          <div class="results">
            <router-link
              v-for="product in getPageResults"
              :key="product.id"
              :to="productRoute(product)"
              class="product-card-wrapper"
            >
              <ProductCard
                :is-market-place="true"
                :product="product"
                :my-stock-room-product="false"
              />
            </router-link>
          </div>
        </div>
        <div
          v-if="numberOfPages > 1"
          class="pagination-wrapper"
        >
          <BasePagination
            :pages="numberOfPages"
            :current-page="currentPage"
            @previous="prevPage"
            @next="nextPage"
            @page-clicked="setPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseLoading from '@/components/common/BaseLoading.vue';
import BasePagination from '@/components/common/BasePagination.vue';
import MarketplaceBreadcrumbs from '@/components/marketplace/common/MarketplaceBreadcrumbs.vue';
import ProductCard from '@/components/marketplace/common/ProductCard.vue';

export default {
  name: 'Category',
  components: {
    BaseLoading,
    BasePagination,
    MarketplaceBreadcrumbs,
    ProductCard,
  },
  computed: {
    ...mapGetters({
      getCategories: 'marketplaceCategory/getCategories',
      getResults: 'marketplaceCategory/getResults',
      getPageResults: 'marketplaceCategory/getPageResults',
      getLoadingStatus: 'marketplaceCategory/getLoadingStatus',
      numberOfPages: 'marketplaceCategory/getNumberOfPages',
      currentPage: 'marketplaceCategory/getPage',
      getPriceRange: 'marketplaceCategory/getPriceRange',
      highestPrice: 'marketplaceCategory/getHighestPrice',
    }),
    priceRange: {
      get() {
        return this.getPriceRange;
      },
      set(priceRange) {
        this.setRange(priceRange);
      },
    },
    breadcrumbs() {
      if (this.$route.params.subcategory) {
        return [
          {
            name: this.parent,
            path: `/marketplace/category/${this.$route.params.category}`,
            active: true,
          },
          {
            name: this.child,
            path: '',
            active: false,
          },
        ];
      }
      return [
        {
          name: this.parent,
          path: `/marketplace/category/${this.$route.params.category}`,
          active: true,
        },
      ];
    },
    parent() {
      return this.$route.params.category
        .toLowerCase()
        .split('-')
        .map((w) => {
          return w.charAt(0).toUpperCase() + w.slice(1);
        })
        .join(' ');
    },
    child() {
      return this.$route.params.subcategory
        .toLowerCase()
        .split('-')
        .map((w) => {
          return w.charAt(0).toUpperCase() + w.slice(1);
        })
        .join(' ')
        .replace('Zz ', '');
    },
  },
  watch: {
    highestPrice() {
      this.setRange([0, this.highestPrice]);
    },
  },
  async beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await vm.setCategory({
        slug: vm.$route.params.subcategory || vm.$route.params.category,
      });
      await vm.fetch();
      vm.priceRange = [0, vm.highestPrice];
    });
  },
  methods: {
    ...mapActions({
      fetchCategories: 'marketplaceCategory/fetchCategories',
      setCategory: 'marketplaceCategory/commitSetCategory',
      fetch: 'marketplaceCategory/fetchProductsByCategorySlug',
      nextPage: 'marketplaceCategory/nextPage',
      prevPage: 'marketplaceCategory/prevPage',
      setPage: 'marketplaceCategory/commitPage',
      setRange: 'marketplaceCategory/commitSetPriceRange',
    }),
    productRoute(p) {
      const { vin, slug, categorySlug } = p;
      return {
        name: 'Product',
        params: { vin, slug, categorySlug },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
#category {
  background: $light-grey;
  position: relative;
}
.content-wrapper {
  padding: 0px 20px 75px 20px;
}
.loading {
  height: 60px;
  width: 100%;
  position: absolute;
  top: 0;
}
.price-range {
  padding: 20px 30px 10px 30px;
  background: $white;
  border-radius: 12px;
}
.filter-label {
  font-size: 0.8em;
  margin: 0 -10px;
  color: $simpatra-blue;
}
.content-container {
  max-width: 1200px;
  margin: 0 auto;
}
.header {
  height: 80px;
  display: flex;
  align-items: center;
}
.feedback {
  font-size: 0.9em;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  color: $simpatra-blue;
}
.weighted {
  font-weight: 600;
}
.content {
  display: flex;
}
.sidebar {
  width: 225px;
}
.results {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
}
.product-card-wrapper {
  margin: 0px 0px 10px 10px;
  text-decoration: none;
}
.pagination-wrapper {
  margin-top: 25px;
}
@media screen and (max-width: 1020px) {
  .sidebar {
    display: none;
  }
  .results {
    margin-left: 0;
    justify-content: center;
  }
  .viewing {
    font-size: 0.8em;
  }
}
</style>
