<template>
  <div class="simpatra-layout">
    <Header class="layout-header" />
    <div class="layout-body-content">
      <router-view />
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "@/components/layout-v2/HomePageHeader.vue";
import Footer from "@/components/layout-v2/HomePageFooter.vue";

export default {
  name: "HomePageLayout",
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.simpatra-layout {
  height: 100%;
  overflow: hidden;
}
.layout-body-content {
  // overflow: auto;
  max-height: 96.5%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
