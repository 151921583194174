<template>
  <div>
    <BaseComponent :is-loading="!pageHasLoaded">
      <template v-if="pageHasLoaded" slot="content">
        <iframe v-if="userType === 'practiceUser'" id="ifr2" style="display: none" :src="labTestPortal" />
        <div v-loading="productLoading" class="product">
          <div class="content-container">
            <div class="header">
              <div v-if="userType === 'practiceUser'" class="lab-test" @click="backToLabTest">
                <i :style="{ color: contentColor }" class="el-icon-back" />
                <label :style="{ color: contentColor }" class="back-panel-label"> back to lab test </label>
              </div>
              <MarketplaceBreadcrumbs v-if="userType === 'orgUser'" :crumbs="breadcrumbs" />
            </div>
            <div class="content">
              <div class="images">
                <ProductImages v-if="productHasImages" :product-details="getSelectedProduct" />
                <div v-else class="placeholder">
                  <div>
                    <img
                      :src="categoryImage(getSelectedProduct.categoryName)"
                      :alt="getSelectedProduct.categoryName"
                      class="placeholder-image"
                    />
                    <p class="placeholder-message">We're sorry, this product doesn't have any images</p>
                  </div>
                </div>
              </div>
              <div class="details">
                <h1 class="name">{{ getSelectedProduct.name }}</h1>
                <div class="origin">
                  <span class="sku">
                    {{ getSelectedProduct.sku }}
                  </span>
                  <span v-if="isLoggedIn && getSelectedProductVendor" class="vendor">
                    By {{ getSelectedProductVendor.name }}
                  </span>
                  <span v-if="!getSelectedProduct.inStock" class="vendor out-of-stock"> Out Of Stock </span>
                </div>
                <div class="vendor-wrapper">
                  <div class="social-wrapper">
                    <ProductSocial :product="getSelectedProduct" :is-restock-details="false" />
                  </div>
                </div>
                <div class="pricing">
                  <p class="price-label">Price</p>
                  <p class="price">${{ getSelectedProduct.price.toFixed(2) }}</p>
                </div>
                <div v-if="userType === 'orgUser'" class="actions">
                  <ProductSignIn v-if="!isLoggedIn" />
                  <ProductRegister v-else-if="requireRegistration" />
                  <ProductNotify v-else-if="!getSelectedProduct.inStock" />
                  <ProductAddToCart v-else />
                </div>
                <div v-if="userType === 'practiceUser'" class="actions">
                  <ProductAddToCart :content-color="contentColor" />
                </div>
                <div
                  v-if="getSelectedProduct.files && getSelectedProduct.files.length > 0 && userType === 'orgUser'"
                  class="attachments"
                >
                  <p class="attachments-label">This product has {{ getSelectedProduct.files.length }} attachment(s)</p>
                  <ProductAttachment v-for="file in getSelectedProduct.files" :key="file.id" :file="file" />
                </div>
                <div class="description">
                  <h2 class="description-header">Product Description</h2>
                  <p class="description-body">
                    {{ getSelectedProduct.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="userType === 'orgUser'" v-loading="moreLoading" class="more-products">
          <div class="content-container bottom-container">
            <p class="carousel-header">More from {{ getSelectedProduct.categoryName }}</p>
            <BaseCarousel
              :category-slug="getSelectedProduct.categorySlug"
              @loading="moreLoading = true"
              @done="moreLoading = false"
            />
          </div>
        </div>
      </template>
    </BaseComponent>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import jwtdecode from "jwt-decode";
import { findCategoryImage } from "@/utils/imagePlaceholder";
import BaseCarousel from "@/components/common/BaseCarousel.vue";
import MarketplaceBreadcrumbs from "@/components/marketplace/common/MarketplaceBreadcrumbs.vue";
import ProductImages from "@/components/marketplace/product/ProductImages.vue";
import ProductSignIn from "@/components/marketplace/product/ProductSignIn.vue";
import ProductRegister from "@/components/marketplace/product/ProductRegister.vue";
import ProductNotify from "@/components/marketplace/product/ProductNotify.vue";
import ProductAddToCart from "@/components/marketplace/product/ProductAddToCart.vue";
import ProductAttachment from "@/components/marketplace/product/ProductAttachment.vue";
import ProductSocial from "@/components/marketplace/product/ProductSocial.vue";
import BaseComponent from "@/components/shared-components/BaseComponent.vue";

export default {
  name: "Product",
  components: {
    BaseCarousel,
    MarketplaceBreadcrumbs,
    ProductImages,
    ProductSignIn,
    ProductRegister,
    ProductNotify,
    ProductAddToCart,
    ProductAttachment,
    ProductSocial,
    BaseComponent,
  },
  data() {
    return {
      pageHasLoaded: false,
      productLoading: true,
      moreLoading: true,
      userType: "",
      contentColor: "",
      requireRegistration: false,
    };
  },
  computed: {
    ...mapGetters("usersModule", ["isLoggedIn"]),
    ...mapGetters("accountsModule", ["getSelectedProductVendor"]),
    ...mapGetters("productsModule", ["getSelectedProduct"]),
    ...mapGetters({
      getProduct: "marketplaceProduct/getProduct",
      getVendor: "marketplaceVendor/getVendor",
      getAction: "marketplaceProduct/getProductAction",
      locationActive: "getCurrentLocationStatus",
      getCurrentUser: "getCurrentUser",
      getSelectedCurrentLocationId: "getSelectedCurrentLocationId",
    }),
    labTestPortal() {
      return `${process.env.VUE_APP_LABTEST_ROOT}`;
    },
    productHasImages() {
      return this.getSelectedProduct.images && this.getSelectedProduct.images.length > 0;
    },
    breadcrumbs() {
      return [
        {
          name: this.getSelectedProduct.categoryName,
          path: `/marketplace/category/${this.getSelectedProduct.categorySlug}`,
          active: true,
        },
        {
          name: this.getSelectedProduct.subcategoryName,
          path: `/marketplace/category/${this.getSelectedProduct.categorySlug}/${this.getSelectedProduct.subcategorySlug}`,
          active: true,
        },
        {
          name: this.getSelectedProduct.name,
          path: "",
          active: false,
        },
      ];
    },
  },
  async mounted() {
    this.userType = localStorage.userType === "practiceUser" ? localStorage.userType : "orgUser";
    this.headerBackground = localStorage.color ? localStorage.color : "#374458";
    this.contentColor = localStorage.contentColor ? localStorage.contentColor : "#f05a28";
    this.practiceId = localStorage.practiceId ? localStorage.practiceId : "";
    const payload = {
      vin: this.$route.params.vin,
      slug: this.$route.params.slug,
    };

    if (this.userType === "practiceUser" && this.getCurrentUser.type === "PATIENT") {
      const data = {
        locationId: this.getSelectedCurrentLocationId,
      };
      await this.fetchLocationDetailsById(data);
    }

    if (this.isLoggedIn && window.location.host === "checkout.medcenter.health") {
      const decodedToken = jwtdecode(localStorage.id_token);
      await this.setLoginDetails(decodedToken);
      const data = {
        locationId: decodedToken.sim.details.locations[0].id,
      };
      await this.fetchLocationDetailsById(data);
    }
    if (this.isLoggedIn) {
      const orgId = localStorage.getItem("orgId");
      payload.queryParams = {
        orgId,
      };
    }

    await this.fetchProductByVinAndSlug(payload);
    await this.fetchVendorByVin(this.$route.params.vin);
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("orgId") &&
      this.userType === "orgUser" &&
      this.isLoggedIn
    ) {
      await this.fetchRegistrations();

      const registrationApproved = await this.getVendorApprovedRegistrationStatus(this.getSelectedProduct.vendorId);
      this.requireRegistration = this.getSelectedProduct.registrationRequired && !registrationApproved;
    }
    this.pageHasLoaded = true;
    this.productLoading = false;
  },
  methods: {
    ...mapActions("productsModule", ["fetchProductByVinAndSlug"]),
    ...mapActions("accountsModule", ["fetchVendorByVin"]),
    ...mapActions({
      fetchProduct: "marketplaceProduct/fetchProductBySlug",
      fetchVendor: "marketplaceVendor/fetchVendorByVin",
      fetchRegistrations: "locationRegistrations/fetchRegistrations",
      clearProduct: "marketplaceProduct/commitClearProduct",
      fetchLocationDetailsById: "fetchLocationDetailsById",
      setLoginDetails: "setLoginDetails",
      getVendorApprovedRegistrationStatus: "locationRegistrations/getVendorApprovedRegistrationStatus",
    }),
    categoryImage(defaultImg) {
      return findCategoryImage(defaultImg);
    },
    backToLabTest() {
      window.location = `${process.env.VUE_APP_LABTEST_ROOT}/simpatra`;
    },
    vendorStore() {
      this.$router
        .push({
          name: "VendorStore",
          params: {
            vendorId: this.getSelectedProductVendor.id,
          },
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
#ifr3 {
  width: 500px;
  height: 500px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-left: 430px;
}
.back-panel-label {
  // color: #f05a28;
  cursor: pointer;
}
i {
  // color: #f05a28;
  padding-right: 5px;
  cursor: pointer;
}
.product {
  background: $light-grey;
  padding: 0px 20px 75px 20px;
  color: $simpatra-blue;
  position: relative;
}
.loading {
  height: 60px;
  width: 100%;
  position: absolute;
  top: 0;
}
.content-container {
  max-width: 1200px;
  margin: 0 auto;
}
.header {
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 0.9em;
}
.content {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
}
.images {
  flex: 1;
  margin-right: 25px;
}
.main-image {
  width: 100%;
}
.placeholder {
  background: $grey;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 120px 20px;
}
.placeholder-image {
  width: 100px;
  opacity: 1;
  margin-bottom: 20px;
}
.placeholder-message {
  font-size: 0.8em;
  opacity: 0.5;
}
.details {
  flex: 1;
}
.name {
  font-family: "Inter";
  font-weight: 600;
  font-size: 2em;
  color: $simpatra-blue;
  margin-bottom: 10px;
  line-height: 100%;
}
.origin {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: $border-default;
  display: flex;
  flex-wrap: wrap;
}
.sku {
  font-size: 0.8em;
  font-weight: 600;
  margin-right: 10px;
}
.vendor {
  font-size: 0.7em;
  color: #989ba9;
}
.out-of-stock {
  color: #ff0000;
  margin-left: 10px;
  font-weight: 700;
}
.social-wrapper {
  // margin-top: -34px;
  margin-bottom: 20px;
  // padding-left: 160px;
}
.pricing {
  margin-bottom: 20px;
}
.price-label {
  font-size: 0.8em;
}
.price {
  font-size: 1.5em;
  font-weight: 600;
}
.actions {
  margin-bottom: 25px;
}
.attachments {
  margin-bottom: 50px;
}
.attachments-label {
  font-size: 0.8em;
  margin-bottom: 5px;
}
.description-header {
  font-family: "Inter";
  font-size: 1em;
  margin-bottom: 10px;
}
.description-body {
  font-size: 0.9em;
  line-height: 1.5em;
}
.more-products {
  background: $grey;
  height: 492px;
}
.bottom-container {
  padding: 50px 0px;
}
.carousel-header {
  text-align: center;
  font-weight: 600;
  font-size: 1.5em;
  color: $simpatra-blue;
}
@media screen and (max-width: 920px) {
  .name {
    font-size: 1.5em;
  }
  .content {
    display: block;
  }
  .images {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .placeholder {
    padding: 60px 20px;
  }
  .carousel-header {
    font-size: 1.25em;
  }
}
</style>
